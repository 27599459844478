var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-recalculate-price-VN1A",
      "title": _vm.$t('reservation.recalculatePriceFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.submitRecalculatePrice
          }
        }, [_c('span', {
          staticClass: "text-uppercase"
        }, [_vm._v(_vm._s(_vm.$t('reservation.recalculatePrice.title')))])])];
      }
    }])
  }, [_c('b-card', {
    attrs: {
      "body-class": "p-1"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.itinerariesInformation')) + ": ")]), _vm._l(_vm.getBookingData.itineraries, function (itinerary, indexItinerary) {
    return _c('div', {
      key: indexItinerary,
      staticClass: "d-flex flex-column py-75"
    }, _vm._l(itinerary, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('div', {
        staticClass: "mr-50 fw-700"
      }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)) + " ")])]);
    }), 0);
  }), _c('b-form-checkbox', {
    staticClass: "my-1",
    attrs: {
      "name": "dob-checkbox",
      "disabled": _vm.isBargainFinder
    },
    model: {
      value: _vm.isDobRecalculate,
      callback: function callback($$v) {
        _vm.isDobRecalculate = $$v;
      },
      expression: "isDobRecalculate"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.withDOB')) + " ")])]), _c('div', {
    staticClass: "text-body fw-600 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.paxInformation')) + ": ")]), _c('ol', {
    staticClass: "mb-50"
  }, _vm._l(_vm.getBookingData.paxLists, function (pax, indexPax) {
    return _c('b-row', {
      key: indexPax,
      staticClass: "fw-700 my-50"
    }, [_c('b-col', {
      staticClass: "d-flex-center justify-content-start",
      staticStyle: {
        "padding": "0.55rem 0"
      },
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_vm._v(" " + _vm._s(pax.paxId) + ". " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")]), _vm.isDobRecalculate ? _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('SelectBirthDate', {
      attrs: {
        "dob": _vm.paxInfoForDOB[indexPax].birthday
      },
      on: {
        "update:dob": function updateDob($event) {
          return _vm.$set(_vm.paxInfoForDOB[indexPax], "birthday", $event);
        }
      }
    })], 1) : _vm._e()], 1);
  }), 1), _c('div', {
    staticClass: "text-heading-4 mb-25"
  }, [_vm._v(" Giá vé: "), _c('span', {
    staticClass: "fw-700 text-airline text-heading-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.hideFee ? _vm.getBookingData.totalAmountToBePaid : _vm.getBookingData.fareInfo.total)))])]), _vm.errorMessage ? _c('BAlert', {
    staticClass: "fw-700 py-75 mt-1 text-center px-75",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e(), _c('BAlert', {
    staticClass: "fw-700 py-75 mt-1 text-center px-75",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" (*) " + _vm._s(_vm.$t('reservation.recalculatePrice[\'The system will automatically recalculate the price for all passengers on the booking\']')) + " ! ")])], 2), _vm.canPriceBargainFinder ? _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-12px justify-content-around"
  }, [_c('b-form-checkbox', {
    staticClass: "mx-1",
    attrs: {
      "name": "retain-checkbox",
      "disabled": _vm.isDobRecalculate
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])]), _c('b-form-checkbox', {
    staticClass: "mx-1",
    attrs: {
      "name": "keepGroup-checkbox"
    },
    model: {
      value: _vm.keepGroup,
      callback: function callback($$v) {
        _vm.keepGroup = $$v;
      },
      expression: "keepGroup"
    }
  }, [_c('div', {
    staticClass: "text-body fw-600 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.keepGroup')) + " ")])])], 1)]) : _vm._e()], 1), _c('BModal', {
    attrs: {
      "id": "modal-recalculate-show-price-VN1A",
      "title": _vm.$t('reservation.showCalculatePrice.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "hide-header-close": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "rounded-pill px-2",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.handleClose(close);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), !_vm.isDobRecalculate ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.handleSavePrice
          }
        }, [_c('span', {}, [_vm._v("LƯU GIÁ MỚI")])]) : _vm._e()];
      }
    }])
  }, [_vm.newPrice ? _c('div', {
    staticClass: "position-relative"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "position-absolute p-75 rounded-circle m-25",
    staticStyle: {
      "top": "0",
      "right": "10px",
      "z-index": "9999"
    },
    attrs: {
      "variant": "flat-secondary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": _vm.handleCopyText
    }
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "text-dark",
    attrs: {
      "icon": "CopyIcon",
      "size": "21",
      "title": _vm.$t('copy')
    }
  })], 1), !_vm.isEmpty(_vm.newPrice.booking) ? [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "70vh",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.newPrice.booking.join('\n'),
      "rows": "16",
      "max-rows": "40"
    }
  })] : _vm._e(), _c('BAlert', {
    staticClass: "py-75 mt-1 text-center px-75",
    attrs: {
      "show": _vm.newPrice.isWarning,
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "fw-700 font-medium-2 text-danger"
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!! ")])])], 2) : _vm._e(), !_vm.isEmpty(_vm.newPriceItinerary) ? _c('div', {
    staticClass: "position-relative"
  }, [_vm.tripDetailsItinerary ? _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightInfo')) + " ")]), _c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.tripDetailsItinerary,
      "rows": "2"
    }
  })], 1) : _vm._e(), !_vm.isEmpty(_vm.newPriceItinerary) ? _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "striped": "",
      "body-class": "p-0",
      "thead-class": "text-center font-weight-bolder text-nowrap",
      "tbody-class": "text-center text-nowrap",
      "fields": ['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'],
      "items": _vm.newPriceItinerary
    },
    scopedSlots: _vm._u([_vm._l(['passengerType', 'net', 'tax', 'total', 'quantity', 'totalAmount'], function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: indexColumn
          }, [_c('span', [_vm._v(_vm._s(_vm.$t("reservation.".concat(data.column))))])])];
        }
      };
    }), {
      key: "cell(passengerType)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.paxType) + " ")];
      }
    }, {
      key: "cell(net)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.netFare)) + " ")];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tax)) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "fw-600"
        }, [_vm._v(_vm._s(_vm.formatCurrency(item.totalPrice)))])];
      }
    }, {
      key: "cell(quantity)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.paxNum ? _vm.formatCurrency(item.paxNum) : 1) + " ")];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "fw-800"
        }, [_vm._v(_vm._s(item.paxNum ? _vm.formatCurrency(item.totalPrice * item.paxNum) : _vm.formatCurrency(item.totalPrice)))])];
      }
    }], null, true)
  }) : _vm._e(), _c('p', {
    class: "text-right text-body text-nowrap mr-md-2 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" Tổng tiền: "), _c('span', {
    class: "text-airline fw-800 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-3')
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.newPriceItinerary.reduce(function (total, item) {
    return total + (item.paxNum ? item.totalPrice * item.paxNum : item.totalPrice);
  }, 0))) + " VND")])]), _c('BAlert', {
    staticClass: "py-75 mt-1 text-center px-75",
    attrs: {
      "show": _vm.isWarningItinerary,
      "variant": "warning"
    }
  }, [_c('span', {
    class: "fw-700 ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI, VUI LÒNG KIỂM TRA !!! ")])])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }